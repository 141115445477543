// <script>
//*************************************************************************************************
//		Price Comparison Script v6 copyright Kaon Softwares ( http://www.kaonsoftwares.com )
//=================================================================================================
//		NOTICE: Do NOT remove the copyright and/or license information from this file.
//				doing so will automatically terminate your rights to use this program.
//*************************************************************************************************

var xmlHttp;
var oTargetDiv	= '';
var object		= '';
var funcName	= '';

function getContentFromUrl(url, targetDiv, method, postData, fName)
{
	if ( url == '' )
	{
		alert ("URL for HTTP Request is empty");
		return;
	}
	if ( targetDiv == '' )
	{
		alert ("Target DIV not specified for HTTP Request");
		return;
	}
	if ( method == '' || method == undefined )
		method = 'GET';

	// because variables in js are global, once set they are not reset and call the same function again and again
	funcName = fName;

	object = GetXmlHttpObject();
	if ( object == null )
	{
		alert ("Browser does not support HTTP Request");
		return;
	}

	oTargetDiv					= targetDiv;
	object.onreadystatechange	= stateChanged4Content;

	try
	{
		object.open(method, url, true);
		object.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		object.send(postData==''?null:postData);
	}
	catch (e)
	{
		alert(e.message);
	}
}

function stateChanged4Content()
{
	if ( object.readyState == 4 || object.readyState == "complete" )
	{
		rVal = object.responseText;

		if ( funcName == '' && rVal == '' )
		{
//			alert("ERROR: Got no response from server for this request.");
			return;
		}

		// If Target DIV is null then just use response to eval
		if ( oTargetDiv == null )
		{
			try
			{
				eval(rVal);
			}
			catch (e)
			{
				alert(e.message+"\n"+rVal);
			}
		}
		// Else Target DIV is assigned response
		else
		{
			oTargetDiv.innerHTML = rVal;
		}

		// Function Name is not empty then that function name is also called
		if ( funcName != '' )
		{
			eval(funcName);
		}
	}
	//	State	Description
	//	0		The request is not initialized
	//	1		The request has been set up
	//	2		The request has been sent
	//	3		The request is in process
	//	4		The request is complete

}

function GetXmlHttpObject()
{
	var xmlHttp = null;
	try
	{
		// Firefox, Opera 8.0+, Safari
		xmlHttp = new XMLHttpRequest();
	}
	catch (e)
	{
		//Internet Explorer
		try
		{
			xmlHttp = new ActiveXObject("Msxml2.XMLHTTP");
		}
		catch (e)
		{
			xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");
		}
	}

	return xmlHttp;
}

// </script>
